<template>
    <div class="box">
        <div class="typebox">
            <div class="typeone" @mouseover="mouseOver(index)" @mouseleave="mouseLeave(index)" v-for="(item,index) in list" :key="index" :style="item.style">
                <img :src="item.img" alt="">
                <div :class="!item.type?'neibox':'neiboxAnimation'">
                    <div class="neibt">
                        <div class="neititle">{{ $t(item.title)}}</div>
                        <div class="neiheng"></div>
                        <div class="neiimg">
                            <img src="../assets/icon.png" alt="">
                        </div>
                    </div>
                    <div class="morebox" v-show="item.type" @click="gopath(index)">
                        MORE+
                    </div>
                </div>
            </div>
            <div class="typebt">
                <img class="typebtimg" src="../assets/4.png" alt="">
            </div>

        </div>
        <div class="titlebox">
            <img class="titleimg" src="../assets/one.png" alt="">
        </div>
        <div class="desbox" :style="$i18n.locale == 'en'?'width:1420px':''">
            <div class="leftbox">
                <img class="titleimg" src="../assets/5.png" alt="">
            </div>
            <div class="rightbox">
                <div class="righttopbox">
                    <div class="topimg">
                        <img class="titleimg" src="../assets/6.png" alt="">
                    </div>
                    <div class="topright">
                        <div class="texttop" :style="$i18n.locale == 'en'?'padding-bottom:30px;line-height: 27px;':''">
                            <span style="font-weight:550;font-size:18px">深圳市尚诚华志进出口有限公司</span>（Shenzhen Shangcheng Huazhi Import and Export Co., LTD）{{$t('info')}}
                        </div>
                        <div class="textbottom" :style="$i18n.locale == 'en'?'margin-bottom:30px;margin-top:30px':''">
                            <div class="bttext">{{$t('phone')}}： 13828809570，{{$t('people')}}</div>
                            <div class="bttext">{{$t('e_mail')}}：2189877@qq.com</div>

                            <div class="bttext">{{$t('address')}}：{{$t('addressinfo')}} </div>

                        </div>
                        <div>{{$t('onlineText')}}</div>
                    </div>
                </div>
                <div class="btom">
                    <div class="ti"></div>
                    <div class="btomtext">{{$t('cooperation')}}</div>
                </div>
            </div>
        </div>
        <div class="titlebox">
            <img class="titleimg" src="../assets/two.png" alt="">
        </div>
        <div class="showbox">
            <el-carousel height="1200px" :interval="4000" :autoplay="!DialogVisible">
                <el-carousel-item v-for="(item,index) in swiperlist" :key="index">
                    <div class="showone">
                        <div class="showimg">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="showone">
                        <div class="showabbox" style=" column-count: 3;transform: translateX(-5%);">
                            <div class="showimgabsolute" v-for="(items,indexs) in swiperInfolist[0]" :key="indexs" @click="showimg(items.infoimg)" :style="`width:${items.width};transform:${items.transform}`">
                                <img :src="items.img" alt="">
                            </div>
                        </div>

                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="showone">
                        <div class="showabbox" style=" column-count: 4;transform: translateX(-10%);">
                            <div class="showimgabsolute" v-for="(items,indexs) in swiperInfolist[1]" :key="indexs" @click="showimg(items.infoimg)" :style="`width:${items.width};transform:${items.transform}`">
                                <img :src="items.img" alt="">
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <el-dialog :visible.sync="DialogVisible" width="900px">
            <img :src="activeInfoimg" alt="">
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "index",
    data() {
        return {
            DialogVisible: false,
            activeInfoimg: "",
            swiperInfolist: [
                [
                    {
                        img: require("../assets/swiperInfo/1.png"),
                        infoimg: require("../assets/swiperInfo/info1.png"),
                        width: "130%",
                    },
                    {
                        img: require("../assets/swiperInfo/4.png"),
                        infoimg: require("../assets/swiperInfo/info4.png"),
                        width: "100%",
                    },
                    {
                        img: require("../assets/swiperInfo/2.png"),
                        infoimg: require("../assets/swiperInfo/info2.png"),
                        width: "85%",
                        transform: "translateX(35%)",
                    },
                    {
                        img: require("../assets/swiperInfo/5.png"),
                        infoimg: require("../assets/swiperInfo/info5.png"),
                        width: "115%",
                    },
                    {
                        img: require("../assets/swiperInfo/3.png"),
                        infoimg: require("../assets/swiperInfo/info3.png"),
                        width: "85%",
                        transform: "translateX(20%)",
                    },
                    {
                        img: require("../assets/swiperInfo/6.png"),
                        infoimg: require("../assets/swiperInfo/info6.png"),
                        width: "110%",
                        transform: "translateX(15%)",
                    },
                ],
                [
                    {
                        img: require("../assets/swiperInfo1/1.png"),
                        infoimg: require("../assets/swiperInfo1/info1.png"),
                        width: "120%",
                    },
                    {
                        img: require("../assets/swiperInfo1/2.png"),
                        infoimg: require("../assets/swiperInfo1/info2.png"),
                        width: "92%",
                        transform: "translateX(25%)",
                    },
                    {
                        img: require("../assets/swiperInfo1/3.png"),
                        infoimg: require("../assets/swiperInfo1/info3.png"),
                        width: "100%",
                        transform: "translateX(15%)",
                    },
                    {
                        img: require("../assets/swiperInfo1/5.png"),
                        infoimg: require("../assets/swiperInfo1/info5.png"),
                        width: "120%",
                        transform: "translateX(13%)",
                    },
                    {
                        img: require("../assets/swiperInfo1/4.png"),
                        infoimg: require("../assets/swiperInfo1/info4.png"),
                        width: "150%",
                        transform: "translateX(13%)",
                    },
                ],
            ],
            swiperlist: [
                {
                    img: require("../assets/show.png"),
                },
                {
                    img: require("../assets/show1.png"),
                },
                {
                    img: require("../assets/show2.png"),
                },
                {
                    img: require("../assets/show3.png"),
                },
            ],
            list: [
                {
                    img: require("../assets/1.png"),
                    title: "service",
                    type: false,
                },
                {
                    img: require("../assets/2.png"),
                    title: "team",
                    style: "transform: translateX(-40px);",
                    type: false,
                },
                {
                    img: require("../assets/3.png"),
                    title: "future",
                    style: "transform: translateX(-80px);",
                    type: false,
                },
            ],
        };
    },
    methods: {
        showimg(v) {
            this.activeInfoimg = v;
            this.DialogVisible = true;
        },
        gopath(v) {
            this.$router.push({ path: "/info", query: { type: v } });
        },
        mouseOver(v) {
            this.list[v].type = true;
        },
        mouseLeave(v) {
            this.list[v].type = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.neibox {
    position: absolute;
    width: calc(100% - 58px);
    height: 200px;
    background: linear-gradient(182deg, rgba(5, 33, 62, 0) 0%, #063770 100%);
    bottom: 4px;
    transform: skewX(-7deg);
    left: 10px;
}
.neiboxAnimation {
    position: absolute;
    width: calc(100% - 58px);
    height: 200px;
    background: linear-gradient(182deg, rgba(5, 33, 62, 0) 0%, #063770 100%);
    bottom: 4px;
    transform: skewX(-7deg);
    left: 10px;
    animation: Morebox_transform 0.5s;
    animation-fill-mode: both;
    -moz-animation: Morebox_transform 0.5s; /* Firefox */
    -webkit-animation: Morebox_transform 0.5s; /* Safari and Chrome */
    -o-animation: Morebox_transform 0.5s; /* Opera */

    -moz-animation-fill-mode: both; /* Firefox */
    -webkit-animation-fill-mode: both; /* Safari and Chrome */
    -o-animation-fill-mode: both; /* Opera */
}
@keyframes Morebox_transform {
    0% {
        left: 10px;
    }
    100% {
        left: -10px;
    }
}

@-moz-keyframes Morebox_transform {
    /* Firefox */
    0% {
        left: 10px;
    }
    100% {
        left: -10px;
    }
}

@-webkit-keyframes Morebox_transform {
    /* Safari and Chrome */
    0% {
        left: 10px;
    }
    100% {
        left: -10px;
    }
}

@-o-keyframes Morebox_transform {
    /* Opera */
    0% {
        left: 10px;
    }
    100% {
        left: -10px;
    }
}
.box {
    width: 100%;

    .typebox {
        width: 1368px;
        display: flex;
        margin: 20px auto;
        position: relative;
        .typeone {
            width: 456px;
            position: relative;

            img {
                width: 100%;
            }

            .neibt {
                position: absolute;
                color: #fff;
                bottom: 50px;
                left: 40px;
                font-weight: bold;
                font-size: 26px;
                color: #ffffff;
                .neiheng {
                    width: 70px;
                    height: 1px;
                    background: #fff;
                    margin: 20px 0 0 0;
                    transform: translateX(-30px);
                }
                .neiimg {
                    position: absolute;
                    bottom: -6px;
                    right: 0px;
                    width: 40px;
                    img {
                        width: 100%;
                    }
                }
            }
            .morebox {
                position: absolute;
                bottom: 50px;
                right: 10px;
                font-weight: 500;
                font-size: 18px;
                color: #ffffff;
                background: rgba(255, 255, 255, 0.2);
                border: 1px solid #ffffff;
                padding: 5px 15px;
                cursor: pointer;
            }
        }
        .typebt {
            position: absolute;
            width: 160px;
            display: flex;
            transform: translateX(-20px);
            align-items: flex-end;
            bottom: 0;
            right: -30px;
            img {
                width: 100%;
            }
        }
    }
    .titlebox {
        margin: 20px auto;
        width: 386px;
        .titleimg {
            width: 386px;
        }
    }
    .desbox {
        width: 1368px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .leftbox {
            width: 378px;
            img {
                width: 100%;
            }
        }
        .rightbox {
            width: calc(100% - 398px);
            .righttopbox {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                .topimg {
                    width: 312px;
                    img {
                        width: 100%;
                    }
                }
                .topright {
                    width: calc(100% - 332px);
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(5, 18, 61, 0.8);
                    .texttop {
                        text-indent: 2em;
                        font-size: 16px;
                        letter-spacing: 1px;
                        line-height: 30px;
                        padding-bottom: 50px;
                        border-bottom: 1px dashed rgba(5, 18, 61, 0.8);
                    }
                    .textbottom {
                        margin-top: 50px;
                        margin-bottom: 50px;
                        .bttext {
                            font-size: 16px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .btom {
                display: flex;
                margin-top: 20px;
                align-items: center;
                .ti {
                    // width: 536px;
                    height: 22px;
                    background-color: #415aab;
                    clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
                    width: calc(100% - 326px);
                    margin-right: 20px;
                }
                .btomtext {
                    font-weight: bold;
                    font-size: 18px;
                    color: #415aab;
                }
            }
        }
    }
    .showbox {
        width: 100%;
        height: 1200px;
        /deep/.el-carousel__arrow {
            background-color: #fff;
            color: #000;
            font-size: 14px;
        }

        .showone {
            width: 100%;
            height: 1200px;
            background-image: url("../assets/back.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            .showimg {
                width: 60%;
                img {
                    width: 100%;
                }
            }
            .showabbox {
                width: 60%;
                column-count: 3; //想要排成的列数
                column-gap: 20px;
                -webkit-column-break-inside: avoid; /*防止BOX里面的内容被分隔到新列中*/
                .showimgabsolute {
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>